var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content content-full content-boxed"
  }, [_c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content"
  }, [Object.keys(_vm.validationErrors).length > 0 ? _c('div', {
    staticClass: "alert alert-danger",
    attrs: {
      "role": "alert"
    }
  }, [_c('h3', {
    staticClass: "alert-heading font-size-h4 my-2"
  }, [_vm._v("Error")]), _vm._l(Object.values(_vm.validationErrors), function (error, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(error))]);
  })], 2) : _vm._e(), _vm.entity._id && !_vm.loading ? _c('EntityForm', {
    attrs: {
      "id": _vm.entity._id,
      "refresh": _vm.refresh,
      "error": _vm.validationErrors
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }) : _c('Spinner', [_vm._v("Loading...")]), _c('div', {
    staticClass: "text-right pb-4"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "disabled": _vm.loadingAction.update
    },
    on: {
      "click": _vm.onUpdate
    }
  }, [_vm.loadingAction.update ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Updating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Update Entity")])])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }